import React from "react"
import { graphql } from "gatsby"
import FlexibleLayout from "../components/DesignSystem/FlexibleLayout"

export const query = graphql`
  query pageQuery($pageId: String!) {
    wpPage(id: { eq: $pageId }) {
      id
      title
      layout {
        pageMarginTop
        designSystem {
          ... on WpPage_Layout_DesignSystem_Slideshow {
            fieldGroupName
            visible
            slides {
              text
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_LinkBlocks {
            fieldGroupName
            visible
            blocks {
              pageLink {
                ... on WpPage {
                  id
                  uri
                  title
                }
              }
              text
              movingText
              backgroundColor
            }
          }
          ... on WpPage_Layout_DesignSystem_TextCenteredHighlighted {
            fieldGroupName
            visible
            text
          }
          ... on WpPage_Layout_DesignSystem_QuickLinksList {
            fieldGroupName
            visible
            links {
              ... on WpPage {
                id
                uri
                title
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_ArticleTitle {
            fieldGroupName
            visible
            title
          }
          ... on WpPage_Layout_DesignSystem_Occhiello {
            fieldGroupName
            text
            visible
          }
          ... on WpPage_Layout_DesignSystem_Quote {
            fieldGroupName
            text
            visible
          }
          ... on WpPage_Layout_DesignSystem_Paragraph {
            fieldGroupName
            text
            visible
          }
          ... on WpPage_Layout_DesignSystem_EventsGrid {
            fieldGroupName
            visible
          }
          ... on WpPage_Layout_DesignSystem_PublicationsGrid {
            fieldGroupName
            visible
            howMany
            selection {
              ... on WpPost {
                id
                uri
                title
                pubblicazione {
                  anno
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_EventsSticky {
            fieldGroupName
            visible
          }
          ... on WpPage_Layout_DesignSystem_PublicationsSticky {
            fieldGroupName
            visible
          }
          ... on WpPage_Layout_DesignSystem_ImageBanner {
            fieldGroupName
            visible
            dida
            fullBleed
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_GifBanner {
            visible
            fieldGroupName
            fullBleed
            image {
              localFile {
                url
              }
            }
            dida
          }
          ... on WpPage_Layout_DesignSystem_VideoBanner {
            visible
            fieldGroupName
            showControls
            videoVimeoId
            dida
          }
          ... on WpPage_Layout_DesignSystem_TagsLinks {
            fieldGroupName
            visible
          }
          ... on WpPage_Layout_DesignSystem_FlexibleRow {
            fieldGroupName
            visible
            type
            image {
              imageOrGif
              dida
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              gif {
                localFile {
                  url
                }
              }
            }
            slides {
              dida
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_HeroSection {
            fieldGroupName
            visible
            text
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_PubblicazioneInfo {
            fieldGroupName
            visible
            info {
              label
              value
            }
            copertina {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_SingleEventLink {
            fieldGroupName
            visible
            event {
              ... on WpPost {
                id
                excerpt
                title
                uri
                translated {
                  translated {
                    localizedWpmlUrl
                    uri
                    excerpt
                    title
                  }
                }
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_AccordionsGroup {
            fieldGroupName
            visible
            accordion {
              content
              label
            }
          }
          ... on WpPage_Layout_DesignSystem_PublicationsRelated {
            fieldGroupName
            visible
            related {
              ... on WpPost {
                id
                uri
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                pubblicazione {
                  anno
                }
              }
            }
          }
          ... on WpPage_Layout_DesignSystem_ButtonsGroup {
            fieldGroupName
            visible
            button {
              label
              type
              link
              file {
                publicUrl
              }
            }
          }
        }
      }
    }
  }
`
const PageTemplate = ({ data, menuState, location }) => {
  return (
    <>
      <FlexibleLayout
        layout={data?.wpPage?.layout?.designSystem}
        pageMarginTop={data?.wpPage?.layout?.pageMarginTop}
        menuState={menuState}
        location={location}
      />
    </>
  )
}

export default PageTemplate
